(() => {
  // on resize and on expand of accordion, making sure that the height of the accordion content is accurate
  const handleAccordion = () => {
    const accordions = document.querySelectorAll('.js-badger-accordion');
    if (accordions?.length) {
      let winWidth = window.innerWidth;
      const badgerInstances = window._badgerInstances;
      const badgerKeys = badgerInstances && Object.keys(badgerInstances);
      if (badgerKeys?.length) {
        const resizeBadgerHeights = () => {
          badgerKeys.forEach(key => {
            window._badgerInstances[key].calculateAllPanelsHeight?.();
          });
        };

        window.Bus.on('resize-accordion-content', () => {
          resizeBadgerHeights();
        });

        // making sure the height of the accordion content is accurate on load.
        window.addEventListener('load', () => {
          resizeBadgerHeights();
        });

        // making sure the height of the accordion content is accurate on resize.
        window.addEventListener('resize', () => {
          const curWidth = window.innerWidth;
          if (winWidth !== curWidth) {
            winWidth = curWidth;
            resizeBadgerHeights();
          }
        });
      }
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', handleAccordion);
  } else {
    handleAccordion();
  }
})();
