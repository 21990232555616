(() => {
  const processForm = ($form: HTMLFormElement) => {
    let $submitBtn: HTMLButtonElement,
      $fasUrlEl: HTMLAnchorElement,
      $inputEl: HTMLInputElement,
      $ghostLink: HTMLAnchorElement,
      fasUrl: string;

    // initiating the variables used across the file
    const initVariables = () => {
      $submitBtn = $form?.querySelector(`.fap__submit`) as HTMLButtonElement;
      $fasUrlEl = $form?.querySelector('.fap__url a') as HTMLAnchorElement;
      $inputEl = $form?.querySelector('.fap__input input') as HTMLInputElement;
      fasUrl = $fasUrlEl?.getAttribute('href') || '';

      $ghostLink = document.createElement('a');
      $ghostLink.target = '_blank';
    };

    // appending the events
    const appendEvents = () => {
      // when form is submitted, stop it and redirect the user to alle.com webpage
      $submitBtn?.addEventListener('click', e => {
        e.preventDefault();
        const zipVal = $inputEl?.value || '';
        let urlToRedirect = fasUrl || '';

        if (urlToRedirect) {
          // if zip value is not present, removing the query param
          if (zipVal) {
            urlToRedirect += zipVal;
          }
          const brandASTMarketParams = localStorage.getItem(
            'brand_ast_marketing_params'
          );

          if (brandASTMarketParams) {
            urlToRedirect += `&${brandASTMarketParams}`;
          }

          $ghostLink.href = urlToRedirect;
          $ghostLink.click();
        }
      });
    };

    initVariables();
    appendEvents();
  };

  const init = () => {
    const $forms = document.querySelectorAll(
      '.fap__form'
    ) as NodeListOf<HTMLFormElement>;

    $forms.forEach($form => {
      processForm($form);
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
