// Stylesheets
import './main.scss';

import './resources/images/favicon.png';

// Imports all component based scss files.
import './components/**/*.scss';

// Imports all component based ts files.
import './components/**/*.ts';

import './menu';

// import favicon here.

interface AAAEM {
  isMobile: boolean;
  isTouch: boolean;
  isAuthorMode: boolean;
  env: string;
  browser: {
    name: string;
    version: string;
  };
  // All utility functions can be found here: https://gitlab.com/venture-moonwalker/aaaem/aaaem-common/-/blob/645c2f4310c2cf0864454035850a95d28e65e68e/ui.frontend/src/global.ts
  utilities: any;
  plugins: {
    tinySlider: any;
    badgerAccordion: any;
    tabbyJs: any;
    plyr: any;
  };
}

declare global {
  interface Window {
    AAAEM: AAAEM;
    Bus: any; // event bus
    OneTrust: any;
    _tnsInstances: any; //carousel
    _tabbyInstances: any; //tabs
    _plyrInstances: any; //vimeo embed
    _badgerInstances: any; //accordion
  }
}

(() => {
  // on first page load when there is no `isISIExpanded` in the localStorage, show some part of ISI and then hide it when expand is clicked
  const handleISI = () => {
    const isISIExpanded = localStorage.getItem('isISIExpanded');
    if (isISIExpanded !== 'true') {
      // appendTempISIContent();
      const $content = document.querySelector(
        '.aaaem-isi-container__isi-content-wrapper'
      ) as HTMLElement;
      const $banner = document.querySelector(
        '.aaaem-isi-banner-content'
      ) as HTMLElement;
      const $dummyDiv = document.createElement('div');
      $dummyDiv.classList.add('isi-temp-content');
      const $dummyDivInner = document.createElement('div');
      $dummyDivInner.classList.add('isi-temp-content__inner');
      $dummyDivInner.innerHTML = $content?.innerHTML || '';
      $dummyDiv.appendChild($dummyDivInner);
      $banner?.appendChild($dummyDiv);
      document.body.classList.add('has-isi-temp-content');

      window.Bus?.on('emu-text:inview', ({ id }) => {
        if (id === 'isi-inview-marking-element') {
          // timer to make sure that the ISI scroll is done
          setTimeout(() => {
            localStorage.setItem('isISIExpanded', 'true');
            document.body.classList.remove('has-isi-temp-content');
          }, 1000);
        }
      });
    }
  };

  const init = () => {
    if (navigator.userAgent.toLowerCase().indexOf('windows') >= 0) {
      document.body.classList.add('os-windows');
    }

    handleISI();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
