(() => {
  const handleRelativeModal = $el => {
    let modalId,
      $wrapper,
      hideClass = 'relative-modal__wrapper--hide';

    // updates the class on the wrapper
    const updateWrapperClass = isModalOpen => {
      $wrapper?.classList.toggle(hideClass, isModalOpen);
    };

    // initiating variables being used across the file
    const initVariables = () => {
      modalId = $el.getAttribute('data-id');
      $wrapper = $el.closest('.relative-modal__wrapper');
    };

    // appending bus events for when the modal is open or closed
    const appendEvents = () => {
      if (window.Bus && modalId && $wrapper) {
        window.Bus.on('emu-modal:open', ({ id }) => {
          if (id === modalId) {
            updateWrapperClass(true);
          }
        });

        window.Bus.on('emu-modal:close', ({ id }) => {
          if (id === modalId) {
            updateWrapperClass(false);
            // making sure that the accordions are resized after the modal is closed.
            window.Bus.emit('resize-accordion-content');
          }
        });
      }
    };

    initVariables();
    appendEvents();
  };

  const init = () => {
    const relativeModals = document.querySelectorAll(
      '.relative-modal'
    ) as NodeListOf<HTMLElement>;
    relativeModals?.forEach($modal => {
      handleRelativeModal($modal);
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
