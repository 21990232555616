(() => {
  // This function will handle the header dropdown in desktop.
  const handleHeaderMenu = () => {
    let headerDropdownLinks,
      header,
      headerMainLinks,
      headerSubNavLinks,
      bodyEl,
      headerCloseBtn,
      navWrapper,
      mobileMenuOpenClass = 'js-is-open',
      isDesktopView = window.matchMedia('(min-width: 1024px)').matches;

    // initializes the variables used inside the function
    const initVariables = () => {
      header = document.querySelector('header.header');
      headerDropdownLinks = header?.querySelectorAll(
        '.emu-navigation__item-parent'
      ) as NodeListOf<HTMLElement>;

      headerMainLinks = header?.querySelectorAll(
        '.emu-navigation__menu--level-0 >.emu-navigation__item'
      ) as NodeListOf<HTMLElement>;

      headerSubNavLinks = header?.querySelectorAll(
        '.emu-navigation__menu--level-1 .emu-navigation__item'
      ) as NodeListOf<HTMLElement>;

      headerCloseBtn = header?.querySelector('.header__menu-close');

      navWrapper = header?.querySelector('.aaaem-navigation__content-wrapper');

      bodyEl = document.body;
    };

    // appends events to the elements
    const appendEvents = () => {
      let winWidth = window.innerWidth;

      // when window is resized, update the constants
      window.addEventListener('resize', () => {
        const curWidth = window.innerWidth;

        // needed to make sure that the window resize event is not triggered by the mobile URL bar hide/show
        // mobile URL bar hide/show only changes height, so checking for window width change
        if (curWidth !== winWidth) {
          winWidth = curWidth;
          isDesktopView = winWidth >= 1024;
        }
      });

      // in desktop show dropdown menu on hover
      // in mobile show dropdown menu on click
      headerDropdownLinks.forEach(el => {
        // Adding required classes to make sure header dropdown is visible.
        el.addEventListener('mouseover', () => {
          if (isDesktopView) {
            el.classList.add('js-toggle-dropdown');
          }
        });

        el.addEventListener('click', () => {
          if (isDesktopView !== true) {
            if (!el.classList.contains('js-toggle-dropdown')) {
              headerDropdownLinks.forEach(item => {
                item.classList.remove('js-toggle-dropdown');
              });
              el.classList.add('js-toggle-dropdown');
            } else if (el.classList.contains('js-toggle-dropdown')) {
              el.classList.remove('js-toggle-dropdown');
            }
          }
        });

        // Removing classes to make sure header dropdown is not visible.
        el.addEventListener('mouseout', () => {
          if (isDesktopView) {
            el.classList.remove('js-toggle-dropdown');
          }
        });
      });
    };

    const markActiveLink = () => {
      // Making sure that active classes are added on click for all the sub nav links.
      headerMainLinks.forEach(el => {
        if (
          el.classList.contains('emu-navigation__item--active') &&
          !el.classList.contains('emu-navigation__item-parent')
        ) {
          const selectedTagLink = el.querySelector('a')?.getAttribute('href');

          headerSubNavLinks.forEach(ele => {
            const currentTagLink = ele.querySelector('a')?.getAttribute('href');

            // checking if the selected sub nav link is already present on header.
            if (currentTagLink && currentTagLink === selectedTagLink) {
              ele.classList.add('emu-navigation__item--active');
            }
          });
        }
      });
    };

    const appendBusEvents = () => {
      if (window.Bus) {
        window.Bus.on('emu-button:click', ({ id }) => {
          // toggling u-hide-overflow-in-mobile class to the body when menu is open/close
          if (id === 'header-menu-open') {
            bodyEl.classList.add('u-hide-overflow-in-mobile');
          } else if (id === 'header-menu-close') {
            bodyEl.classList.remove('u-hide-overflow-in-mobile');
          } else if (id === 'cs-isi-toggle') {
            // when ISI button is clicked if the mobile menu dropdown is clicked, close the menu
            if (
              headerCloseBtn &&
              navWrapper.classList.contains(mobileMenuOpenClass)
            ) {
              headerCloseBtn.click?.();
            }
          }
        });
      }
    };

    // function calls
    initVariables();
    appendEvents();
    markActiveLink();
    appendBusEvents();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', handleHeaderMenu);
  } else {
    handleHeaderMenu();
  }
})();
