(() => {
  //scroll section functionality
  const scrollHandle = () => {
    const menuItems = document.querySelectorAll(
      '.treatment-plan__scroll-menu-text ul li a'
    ) as NodeListOf<HTMLAnchorElement>;
    const sections = document.querySelectorAll(
      '.treatment-plan__scroll-content'
    ) as NodeListOf<HTMLElement>;

    // Function to highlight the active menu item
    const setActiveMenuItem = (id: string | undefined) => {
      menuItems.forEach(item => {
        item.classList.remove('active');
        if (item.getAttribute('href') === `#${id}`) {
          item.classList.add('active');
        }
      });
    };

    // IntersectionObserver callback
    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setActiveMenuItem(entry.target.id);
        }
      });
    };

    // IntersectionObserver options
    const observerOptions: IntersectionObserverInit = {
      threshold: [0.5], // Adjust this value as needed
    };

    // Create an IntersectionObserver
    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    // Observe each section
    sections.forEach(section => {
      observer.observe(section);
    });

    if (menuItems.length) {
      menuItems.forEach((item, i) => {
        if (i === 0) {
          item.classList.add('active');
        }

        const elId = item.getAttribute('href')?.split('#')[1];
        const scrollToEl =
          elId && (document.querySelector(`#${elId}`) as HTMLElement);
        if (scrollToEl) {
          item.addEventListener('click', e => {
            e.preventDefault();

            // scrolling to the element
            scrollToEl.scrollIntoView({
              behavior: 'smooth',
            });
          });
        }
      });
    }
  };

  const init = () => {
    scrollHandle();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
