(() => {
  const initCustomHideDropdown = $el => {
    let $dropdownBtn: HTMLButtonElement, elId;

    // toggle dropdown
    const toggleDropdown = (show?) => {
      if (show === true) {
        $el?.classList.add('custom-hide-dropdown--show');
      } else if (show === false) {
        $el?.classList.remove('custom-hide-dropdown--show');
      } else {
        $el?.classList.toggle('custom-hide-dropdown--show');
      }
    };

    // variables used across the file
    const initVariables = () => {
      $dropdownBtn = $el.querySelector(
        '.emu-dropdown-menu__button'
      ) as HTMLButtonElement;
      elId = $el?.id;
    };

    // appending events
    const appendEvents = () => {
      const isTouchDevice = window.matchMedia('(pointer: coarse)').matches;

      $dropdownBtn.addEventListener('click', toggleDropdown);

      if (isTouchDevice !== true) {
        $el.addEventListener('mouseover', () => {
          toggleDropdown(true);
        });
        $el.addEventListener('mouseout', () => {
          toggleDropdown(false);
        });
      }

      window.Bus.on('emu-form-select:change', ({ id }) => {
        if (id === elId) {
          toggleDropdown(false);
        }
      });
    };

    if ($el) {
      initVariables();
      appendEvents();
    }
  };

  const init = () => {
    const $dropdowns = document.querySelectorAll('.custom-hide-dropdown');
    $dropdowns.forEach($dropdown => {
      initCustomHideDropdown($dropdown);
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
