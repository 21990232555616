(() => {
  const handleCarousel = () => {
    const carouselInstances = window?._tnsInstances as object;

    if (carouselInstances) {
      Object.values(carouselInstances).forEach((instance: any) => {
        //to include all the slide change actions
        instance.events.on('transitionEnd', info => {
          const slideItems = info.slideItems as HTMLCollection;

          instance?.updateSliderHeight?.();

          if (slideItems?.length) {
            const prevActiveSlide = slideItems[info.indexCached]; // indexCached is the previous active slide index

            //to reset the iframe video inside all carousel on slide change
            const iframe = prevActiveSlide?.querySelector(
              'iframe'
            )! as HTMLIFrameElement;
            if (iframe) {
              iframe.src = iframe.src; // this is needed to reset the iframe on slide change
            }
          }
        });
      });
    }
  };
  const init = () => {
    handleCarousel();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
