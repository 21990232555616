(() => {
  const initGallery = ($el: HTMLElement) => {
    let $genderDropdown: HTMLElement,
      $partDropdown: HTMLElement,
      $genderDropdownSelect: HTMLSelectElement,
      $partDropdownSelect: HTMLSelectElement,
      $tabEl: HTMLElement,
      $tabPanels: NodeListOf<HTMLElement>,
      $tabTriggers: NodeListOf<HTMLAnchorElement>,
      $carousel: HTMLElement,
      $carouselSlides: NodeListOf<HTMLElement>,
      carouselInitiated = false,
      carouselOptions: any,
      genderDropdownId,
      partDropdownId,
      carouselId,
      curSelectedPart,
      curSelectedGender,
      selectedPart = 'all',
      selectedGender = 'female';

    // updates the active filter image tab based on the user's selection
    const updateFilterImgs = () => {
      $el.classList.add('gallery--tabs-ready');
      let activeTab = 0;
      $tabPanels.forEach((el, i) => {
        if (
          el.querySelector(
            `.gallery__filters-img-container--${selectedGender}.gallery__filters-img-container--${selectedPart}`
          )
        ) {
          activeTab = i;
        }
      });
      $tabTriggers?.[activeTab]?.click?.();
    };

    // updates the active filter image tab based on the user's selection
    const updateCarouselLabel = () => {
      $el
        ?.querySelector('.gallery__carousel-label--show')
        ?.classList?.remove('gallery__carousel-label--show');

      $el
        .querySelector(
          `.gallery__carousel-label--${selectedGender}.gallery__carousel-label--${selectedPart}`
        )
        ?.classList?.add('gallery__carousel-label--show');
    };

    const showError = () => {
      $el.classList.add('gallery--has-error');
      $el
        .querySelector(
          `.gallery__error--${selectedPart}.gallery__error--${selectedGender}`
        )
        ?.classList.add('gallery__error--show');
    };

    const hideError = () => {
      $el.classList.remove('gallery--has-error');
      $el
        .querySelector('.gallery__error--show')
        ?.classList.remove('gallery__error--show');
    };

    // INTENTIONALLY COMMENTED - might need again
    // const updateSliderHeightAfterImgLoad = () => {
    //   const info = window._tnsInstances['gallery__carousel'].getInfo();
    //   const $firstSlide = info?.slideItems?.[info?.index];
    //   if ($firstSlide) {
    //     const $lastImg = $firstSlide.querySelector(
    //       'img:last-of-type'
    //     ) as HTMLImageElement;
    //     if ($lastImg?.complete) {
    //       window._tnsInstances[carouselId]?.updateSliderHeight?.();
    //     } else {
    //       $lastImg?.addEventListener('load', () => {
    //         window._tnsInstances[carouselId]?.updateSliderHeight?.();
    //       });
    //     }
    //   }

    //   window._tnsInstances[carouselId]?.updateSliderHeight?.();
    // };

    // adds new instance with the carousel options
    const updateCarouselInst = () => {
      const $carouselControls = document.querySelector(
        `#aaaem-carousel-${carouselId}__controls`
      ) as HTMLElement;
      // making sure that the carousel controls are not displayed none
      if ($carouselControls) {
        $carouselControls.style.display = '';
      }

      window._tnsInstances[carouselId] = window.AAAEM.plugins.tinySlider({
        ...carouselOptions,
        container: `#${carouselId} .aaaem-carousel__content`,
        controlsContainer: `#aaaem-carousel-${carouselId}__controls`,
      });

      $el.classList.remove('cs-loader__wrapper--show');
    };

    // updates the carousel slides with the matching user selections(parts and gender)
    const updateCarouselSlides = () => {
      hideError();
      if ($carouselSlides.length) {
        let count = 0;
        $el.classList.add('cs-loader__wrapper--show');
        window._tnsInstances[carouselId]?.destroy?.();
        const $carouselWrapper = $carousel.querySelector(
          '.aaaem-carousel__content'
        )! as HTMLElement;
        $carouselWrapper.innerHTML = '';

        $carouselSlides.forEach($slideEl => {
          const $slideMain = $slideEl.querySelector(
            '.emu-carousel__item-container'
          ) as HTMLElement;
          if (selectedPart === 'all') {
            if (
              $slideMain?.classList.contains(
                `gallery__carousel-slide--${selectedGender}`
              )
            ) {
              $carouselWrapper.append($slideEl);
              count += 1;
            }
          } else {
            if (
              $slideMain?.classList.contains(
                `gallery__carousel-slide--${selectedGender}`
              ) &&
              $slideMain?.classList.contains(
                `gallery__carousel-slide--${selectedPart}`
              )
            ) {
              $carouselWrapper.append($slideEl);
              count += 1;
            }
          }
        });

        if (count > 0) {
          updateCarouselInst();
        } else {
          showError();
          $el.classList.remove('cs-loader__wrapper--show');
        }
      }
    };

    // when the carousel is ready and has all the content that is necessary to load, then append events and then update the gallery with appropriate items in carousel
    const carouselReady = () => {
      if (carouselInitiated) {
        return;
      }

      carouselInitiated = true;
      // carouselInst.destroy?.();
      $carouselSlides = $carousel.querySelectorAll(
        '.emu-carousel__item:not(.tns-slide-cloned)'
      ) as NodeListOf<HTMLElement>;

      // making sure that the page fragment code is not triggered again by removing the muban data-component attribute
      $carouselSlides.forEach($slide => {
        $slide
          .querySelector('[data-component="pageFragment"]')
          ?.removeAttribute('data-component');
      });
      appendEvents();
      updateGallery();
    };

    // triggers load event on the page fragments present in the carousel
    const loadCarouselItems = () => {
      $el.classList.add('cs-loader__wrapper--show');
      const $curSlidePFs = $el.querySelectorAll(
        '.emu-carousel__item:not(.tns-slide-cloned) .emu-pf'
      );

      let loadedCarouselCount = 0;
      const curCarouselSlideLength = $curSlidePFs?.length;
      const pfMapper = {};
      let timer;

      const carouselPFCallback = (id, pfId, $slidePF, isLoaded = true) => {
        if (id === pfId) {
          if (isLoaded) {
            $slidePF.classList.add('pf-loaded');
          } else {
            $slidePF.classList.add('pf-load-failed');
          }
          loadedCarouselCount += 1;
          if (loadedCarouselCount === curCarouselSlideLength) {
            if (timer) {
              clearTimeout(timer);
            }
            // timer to let the DOM settle
            timer = setTimeout(() => {
              carouselReady();
            }, 400);
          }
        }
      };

      $curSlidePFs?.forEach($slidePF => {
        const pfId = $slidePF?.id;
        if (pfId) {
          if (pfMapper[pfId] !== true) {
            pfMapper[pfId] = true;
            window.Bus.on('emu-pf:loaded', ({ id }) => {
              carouselPFCallback(id, pfId, $slidePF);
            });

            window.Bus.on('emu-pf:loadingFailed', ({ id }) => {
              carouselPFCallback(id, pfId, $slidePF, false);
            });
            window.Bus.emit(`emu-pf-${pfId}-load`);
          }
        }
      });
    };

    // takes selectedGender and selectedPart values and updates the DOM with appropriate content
    const updateGallery = () => {
      if (selectedPart === '--') {
        selectedPart = 'all';
      }
      // if it is already the same as selected, just redirect to the first slide instead of filtering
      if (
        carouselId &&
        curSelectedPart === selectedPart &&
        curSelectedGender === selectedGender
      ) {
        window._tnsInstances?.[carouselId]?.goTo?.(0);
        return;
      }

      // removing previously added class
      $el.classList.remove(`gallery--${curSelectedPart}-${curSelectedGender}`);

      curSelectedGender = selectedGender;
      curSelectedPart = selectedPart;

      // adding class name for the gallery for styling purposes
      $el.classList.add(`gallery--${selectedPart}-${selectedGender}`);

      // updating the images in the filter
      updateFilterImgs();

      // updates the carousel's titles (all, female-abdomen etc)
      updateCarouselLabel();

      // updates Carousel Items
      updateCarouselSlides();
    };

    // re-creating carousel options
    const updateCarouselOptions = () => {
      carouselOptions = Object.assign(
        {},
        window[`_Carousel-${carouselId}-Config`]
          ? JSON.parse(window[`_Carousel-${carouselId}-Config`])
          : {},
        $carousel.dataset
      );
      delete carouselOptions?.component;
      delete carouselOptions?.emitInit;
      delete carouselOptions?.emitInview;
      delete carouselOptions?.emitInviewThreshold;
      delete carouselOptions?.emitNavClick;
      delete carouselOptions?.emitNextClick;
      delete carouselOptions?.emitPrevClick;
      delete carouselOptions?.emitSlide;
      delete carouselOptions?.emuType;
      delete carouselOptions?.gtmAttribute;
      delete carouselOptions?.totalSlides;
      carouselOptions.arrowKeys = true;
      carouselOptions.controlsText = carouselOptions.controlsText.split(',');
      carouselOptions.autoplayTimeout = carouselOptions.autoplayDelay;
      delete carouselOptions.autoplayDelay;
      Object.keys(carouselOptions).forEach(objKey => {
        if (carouselOptions[objKey] === 'true') {
          carouselOptions[objKey] = true;
        } else if (carouselOptions[objKey] === 'false') {
          carouselOptions[objKey] = false;
        }
      });
    };

    // variables used across the file
    const initVariables = () => {
      $genderDropdown = $el.querySelector(
        '.gallery__filters-dropdown--gender'
      ) as HTMLElement;
      $partDropdown = $el.querySelector(
        '.gallery__filters-dropdown--part'
      ) as HTMLElement;
      $genderDropdownSelect = $genderDropdown?.querySelector(
        'select'
      ) as HTMLSelectElement;
      $partDropdownSelect = $partDropdown?.querySelector(
        'select'
      ) as HTMLSelectElement;
      $tabEl = $el.querySelector('.gallery__filters-img-tabs') as HTMLElement;
      $tabPanels = $tabEl.querySelectorAll(
        '.aaaem-tabs__tabpanel'
      ) as NodeListOf<HTMLElement>;
      $tabTriggers = $tabEl.querySelectorAll(
        '.aaaem-tabs__tab a'
      ) as NodeListOf<HTMLAnchorElement>;
      $carousel = $el.querySelector('.gallery__carousel') as HTMLElement;
      carouselId = $carousel?.id;
      genderDropdownId = $genderDropdown?.id;
      partDropdownId = $partDropdown?.id;
      $carouselSlides = $carousel.querySelectorAll(
        '.emu-carousel__item:not(.tns-slide-cloned)'
      ) as NodeListOf<HTMLElement>;

      // recreating carousel options and deleting unnecessary options
      updateCarouselOptions();
    };

    // appending events
    const appendEvents = () => {
      // when select dropdown is changed, update the gallery with the appropriate content
      window.Bus.on('emu-form-select:change', ({ id, selectOptions }) => {
        if (id === genderDropdownId) {
          selectedGender = selectOptions.filter(e => e.selected)?.[0]?.value;
          updateGallery();
        }

        if (id === partDropdownId) {
          selectedPart = selectOptions.filter(e => e.selected)?.[0]?.value;
          updateGallery();
        }
      });

      window.Bus.on('emu-image-map:areaClick', ({ title }) => {
        if (title.indexOf(carouselId) >= 0) {
          const genderAndPart = title.replace(`${carouselId}-`, '').split('-');
          if (genderAndPart.length === 2) {
            selectedGender = genderAndPart[0];
            selectedPart = genderAndPart[1];
            $genderDropdownSelect.value = selectedGender;
            $partDropdownSelect.value = selectedPart;

            //@ts-ignore
            $partDropdownSelect?._update?.();
            //@ts-ignore
            $genderDropdownSelect?._update?.();
            updateGallery();
          }
        }
      });
    };

    if ($el) {
      initVariables();

      // @ts-ignore
      selectedPart = $partDropdownSelect?.value;
      // @ts-ignore
      selectedGender = $genderDropdownSelect?.value;

      // first load the carousel content
      // then save the HTML
      // then filter and update the carousel
      loadCarouselItems();
    }
  };

  const init = () => {
    const $galleries = document.querySelectorAll(
      '.gallery'
    ) as NodeListOf<HTMLElement>;
    $galleries.forEach($gallery => {
      initGallery($gallery);
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
